<template>
    <div class="my-box">
        <div class="select-book-box">
            <van-dropdown-menu>
                <van-dropdown-item v-model="bookValue" :options="bookOption" @change="bookChange" />
            </van-dropdown-menu>
        </div>
        <div class="content-box">
            <van-list
                v-model="loading"
                :finished="finished"
                offset="100"
                finished-text="数据加载完毕"
                @load="onLoad"
            >
                <div class="content-item" v-for="item in list" :key="item.id">
                    <div class="left">
                        <img :src="item.img" alt />
                    </div>
                    <div class="right">
                        <div>
                            <span>{{ item.realName }}</span>
                            <span>{{ item.certName }}</span>
                        </div>
                        <div>{{ item.mobile }}</div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { DropdownMenu, DropdownItem, List } from "vant";
import { certificateList } from "@/api/home";
import { getUserExpandInfoByTeacher } from "@/api/my";
export default {
    components: {
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [List.name]: List,
    },
    data() {
        return {
            bookValue: "",
            bookOption: [],
            list: [],
            loading: false,
            finished: false,
            page: 0,
            limit: 10
        };
    },
    async created() {
        document.title = "我的学员";
        this.$store.commit("updateBarText", "我的学员");
        await this.init();
    },
    methods: {
        bookChange(value) {
            this.bookValue = value
            this.getOnceList()
        },
        onLoad() {
            this.page = this.page + 1
            if (this.bookValue) {
                this.getList()
            }
        },
        getOnceList() {
            getUserExpandInfoByTeacher({
                page: this.page,
                certId: this.bookValue,
                limit: this.limit,
            }).then(res => {
                const { data } = res.data;
                if (data.data.length == 0) {
                    this.finished = true;
                    this.list = data.data
                } else {
                    this.list = data.data
                    if (data.current_page == data.last_page) {
                        this.finished = true;
                    } else {
                        this.loading = true;
                    }
                }
            })
        },
        getList() {
            getUserExpandInfoByTeacher({
                page: this.page,
                certId: this.bookValue,
                limit: this.limit,
            }).then(res => {
                const { data } = res.data;

                if (data.data.length == 0) {
                    this.finished = true;
                } else {
                    this.list = [...this.list, ...data.data]
                    if (data.current_page == data.last_page) {
                        this.finished = true;
                    } else {
                        this.loading = true;
                    }
                }
            })
        },
        async init() {
            await certificateList().then(res => {
                let tempArray = []
                res.data.data.forEach(element => {
                    let tempObject = {}
                    tempObject.text = element.name
                    tempObject.value = element.id
                    tempArray.push(tempObject)
                });
                this.bookOption = tempArray
                this.bookValue = tempArray[0].value
                this.getList()
            })
        },
    },
};
</script>

<style lang="less" scoped>
.my-box {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    padding-top: 70px;
    .select-book-box {
        // background: red;
    }
    .content-box {
        .content-item {
            height: 100px;
            margin-top: 15px;
            display: flex;
            border-bottom: 1px solid #d6d6d6;
            .left {
                height: 80px;
                width: 80px;
                > img {
                    height: 80px;
                    width: 80px;
                }
            }
            .right {
                div {
                    &:nth-of-type(1) {
                        display: flex;
                        width: 210px;
                        justify-content: space-between;
                        padding-top: 16px;
                        padding-left: 10px;
                        color: #203152;
                        font-size: 16px;
                    }
                    &:nth-of-type(2) {
                        padding-top: 5px;
                        padding-left: 10px;
                        color: #203152;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>